<template>
  <div class="bg">
    <!-- <Header :currentNav="0"></Header> -->

    <div class="fullImg">
      <div>
        <img src="../assets/images/header/bg.jpg" />
      </div>
    </div>

    <div class="news">
      <div class="newstitle">
        <div class="l">What’s New</div>
        <div class="r" @click="$router.push('/news')">View More</div>
      </div>
      <div class="cards flexBox">
        <div
          class="card"
          v-for="item in list"
          :key="item.guid"
          style="cursor: pointer"
          @click="
            $router.push({
              path: `/newsDetail/${item.guid}`,
            })
          "
        >
          <el-card>
            <div>
              <img class="card-img" :src="item.article_image" alt="" />
              <div class="card-body">
                <div class="card-title">
                  {{ item.article_title }}
                </div>
                <div class="card-time">
                  Release date：{{
                    $dayjs(item.article_publish_time * 1000).format('ll')
                  }}
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <div class="fullImg">
      <div><img src="../assets/images/index/homebar1.jpg" /></div>
    </div>
    <div class="fullImg">
      <div><img src="../assets/images/index/dmzcon.jpg" /></div>
    </div>
    <div class="fullImg">
      <div><img src="../assets/images/index/tes.png" /></div>
    </div>
    <!-- 
    <div class="fullImg">
      <div style="text-align: center">
        <img src="../assets/images/index/homebar3.jpg" />
      </div>
    </div> -->
    <!-- <div class="fullImg">
      <div><img src="../assets/images/index/homebar2.jpg" /></div>
    </div> -->
    <!-- <div class="fullImg">
      <div class="logoTitle">
        <div>DMZ is a founding member of</div>
        <div style="display: flex; justify-content: space-between">
          <div>the “Asia-Pacific RWA Tokenization Platform”</div>
          <div class="cursor viewmore" @click="goAprtp">VIEW MORE</div>
        </div>
      </div>
      <div class="logoBox">
        <img
          class="bn"
          src="../assets/images/index/homebar4.jpg"
          @click="goAprtp"
        />

        <div class="logoList">
          <div class="l" style="flex: 1">
            <div class="row">
              <div class="l">
                <div>Technology Platform</div>
              </div>
              <a href="https://www.chainup.com" target="_blank">
                <img
                  src="../assets/images/icons/row11.png"
                  alt=""
                  class="row11"
              /></a>
              <a href=" https://www.sgtox.com" target="_blank">
                <img
                  src="../assets/images/icons/row12.png"
                  alt=""
                  class="row12"
              /></a>
              <a href=" https://sg.alibabacloud.com" target="_blank">
                <img
                  src="../assets/images/icons/row13.png"
                  alt=""
                  class="row13"
              /></a>
              <a href="#">
                <img
                  src="../assets/images/icons/row14.png"
                  alt=""
                  class="row13"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>Banks</div>
              </div>
              <a href="https://eng.spdb.com.cn/" target="_blank">
                <img
                  src="../assets/images/icons/row21.png"
                  alt=""
                  class="row21"
              /></a>
              <a href="https://sg.cmbi.com/en-US/" target="_blank">
                <img
                  src="../assets/images/icons/row22.png"
                  alt=""
                  class="row22"
              /></a>
              <a href="https://www.qnb.com/" target="_blank">
                <img
                  src="../assets/images/icons/row23.png"
                  alt=""
                  class="row23"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>Asset Managers</div>
              </div>
              <a href="http://www.nanhua-am.sg/" target="_blank">
                <img
                  src="../assets/images/icons/row31.png"
                  alt=""
                  class="row31"
              /></a>
              <a href="http://www.cwsi.com.hk/" target="_blank">
                <img
                  src="../assets/images/icons/row32.png"
                  alt=""
                  class="row32"
              /></a>
              <a href="https://penco-capital.com/" target="_blank">
                <img
                  src="../assets/images/icons/row33.png"
                  alt=""
                  class="row33"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>Real Estate</div>
              </div>
              <a href="https://www.propnex.com/" target="_blank">
                <img
                  src="../assets/images/icons/row41.png"
                  alt=""
                  class="row41"
              /></a>
              <a href="https://www.pgbc.com.my/" target="_blank">
                <img
                  src="../assets/images/icons/row42.png"
                  alt=""
                  class="row42"
              /></a>
              <a href="#" target="_blank">
                <img
                  src="../assets/images/icons/row43.png"
                  alt=""
                  class="row43"
              /></a>
              <a href="https://www.linbaq.com" target="_blank">
                <img
                  src="../assets/images/icons/row44.png"
                  alt=""
                  class="row44"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>Intangible Asset</div>
              </div>
              <a href="https://www.iq.com/" target="_blank">
                <img
                  src="../assets/images/icons/row51.png"
                  alt=""
                  class="row51"
              /></a>
            </div>
          </div>
          <div class="r">
            <div class="row">
              <div class="l">
                <div>Sustainability</div>
              </div>
              <a href="https://www.climateimpactx.com/" target="_blank">
                <img
                  src="../assets/images/icons/row61.png"
                  alt=""
                  class="row61"
              /></a>
              <a href="http://www.tandidi.com" target="_blank">
                <img
                  src="../assets/images/icons/row62.png"
                  alt=""
                  class="row62"
              /></a>
              <a href="https://www.milltrust.com/" target="_blank">
                <img
                  src="../assets/images/icons/row63.png"
                  alt=""
                  class="row63"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>VC / Community</div>
              </div>
              <a href="https://www.abcde.com/" target="_blank">
                <img
                  src="../assets/images/icons/row71.png"
                  alt=""
                  class="row71"
              /></a>
              <a href="http://www.iccafe.sg/" target="_blank">
                <img
                  src="../assets/images/icons/row72.png"
                  alt=""
                  class="row72"
              /></a>
              <a href="https://uweb.ai/en/" target="_blank">
                <img
                  src="../assets/images/icons/row73.png"
                  alt=""
                  class="row73"
              /></a>
              <a href="https://alchemypay.org/" target="_blank">
                <img
                  src="../assets/images/icons/row74.png"
                  alt=""
                  class="row74"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>Research Institute and Associations</div>
              </div>
              <a href="https://globalfintechinstitute.org/" target="_blank">
                <img
                  src="../assets/images/icons/row81.png"
                  alt=""
                  class="row81"
              /></a>
              <a
                href="https://fisf.fudan.edu.cn/page-GFRC.html"
                target="_blank"
              >
                <img
                  src="../assets/images/icons/row82.png"
                  alt=""
                  class="row82"
              /></a>
              <a href="https://nus.edu.sg/" target="_blank">
                <img
                  src="../assets/images/icons/row83.png"
                  alt=""
                  class="row83"
              /></a>
            </div>
            <div class="row">
              <div class="l">
                <div>Adviser</div>
              </div>
              <a href="https://www.anext.com.sg/" target="_blank">
                <img
                  src="../assets/images/icons/row91.png"
                  alt=""
                  class="row91"
              /></a>
              <a href="https://singaporefintech.org/" target="_blank">
                <img
                  src="../assets/images/icons/row92.png"
                  alt=""
                  class="row92"
              /></a>
              <a href="https://singaporeblockchain.org/" target="_blank">
                <img
                  src="../assets/images/icons/row93.png"
                  alt=""
                  class="row93"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="qabox">
      <div class="safeW">
        <div
          class="faq"
          @click="
            $router.push({
              name: 'knowledge',
            })
          "
        >
          FAQ
        </div>
        <div class="qacontainer" style="flex: 1">
          <div class="qaUnit" v-for="(item, index) in qList" :key="index">
            <div class="q" @click="qslide(index)">
              <div class="arrowdown">
                <i class="el-icon-minus" v-if="qStateList[index] == true"></i>
                <i class="el-icon-plus" v-else></i>
              </div>
              {{ item.article_title }}
            </div>
            <div class="a" v-if="qStateList[index] == true">
              <div v-html="item.article_content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Bottom></Bottom>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Bottom from '@/components/Bottom'
import { smsCode, loginMobile, getPost, getGet } from '../api/apiList'
import { getAboutList } from '@/api/about'

import qs from 'qs'
export default {
  components: { Header, Bottom },
  data() {
    return {
      qStateList: [],
      qList: [],
      indexSwipe: {},
      list: [],
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    // var that = this
    // this.indexSwipe = new Swiper('.swiper-container', {
    //   autoplay: false,
    //   loop: true,
    //   pagination: {
    //     el: '.swiper-pagination',
    //   },
    //   on: {
    //     tap: function (swiper, event) {
    //       that.$router.push({
    //         path: '/reg',
    //       })
    //     },
    //     resize:function(){
    //       setTimeout(()=>{
    //         that.indexSwipe.update();
    //       },100)
    //     }
    //   },
    // })

    setTimeout(() => {
      this.initSwipe()
    }, 1000)

    //this.qStateList = [];

    this.getIndexQa()
  },
  methods: {
    async getList() {
      const res = await getAboutList('news', 1, 3)
      console.log(res)
      this.list = res.data.articles
      this.total = Number(res.data.total_count)
    },
    goAprtp() {
      window.open(' https://aprtp.com')
    },
    initSwipe() {
      var that = this
      this.indexSwipe = new Swiper('.swiper-container', {
        autoplay: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
        },
        on: {
          tap: function (swiper, event) {
            that.$router.push({
              path: '/reg',
            })
          },
          resize: function () {
            setTimeout(() => {
              that.indexSwipe.update()
            }, 100)
          },
        },
      })
    },

    goreg() {
      this.$router.push({
        path: '/reg',
      })
    },

    goinvestList() {
      this.$router.push({
        path: '/investList',
      })
    },

    getIndexQa() {
      let url1 = 'stox_home/index_qa'
      let data1 = {}

      getPost(url1, data1).then((res) => {
        if (res.code == 1) {
          this.qList = res.data

          for (var i = 0; i < res.data.length; i++) {
            this.qStateList.push(false)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    qslide(i) {
      // console.log('e',e);
      for (var j = 0; j < this.qList.length; j++) {
        if (j == i) {
        } else {
          this.qStateList[j] = false
        }
      }
      this.qStateList[i] = !this.qStateList[i]
      this.$forceUpdate()
      console.log('qStateList', this.qStateList)
    },
  },
  watch: {},
}
</script>

<style lang="less" scoped>
.bg {
  width: 100%;
  background: #000;
}

.fullImg {
  position: relative;
  min-width: 1280px;
  overflow-x: hidden;
  margin: 0 auto;
  .logoTitle {
    left: 0 !important;
    width: 1200px;
    margin: 50px auto;
    line-height: 50px;
    font-size: 30px;
    color: #fff;
  }
  > div {
    position: relative;
    width: 1920px;
    left: 50%;
    margin-left: -960px;
    img {
      width: 1920px;
      vertical-align: bottom;
    }
    .viewmore {
      font-size: 18px;
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-container > .swiper-wrapper > .swiper-slide {
  text-align: center;
  font-size: 18px;
  position: relative;

  width: 100%;
  height: 725px;
}
::v-deep .swiper-container {
  // --swiper-theme-color: #ff6600;
  .swiper-pagination-bullet {
    background: white;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: blue;
  }
}

.qabox {
  background: url('../assets/images/index/faqbg.jpeg') no-repeat;
}

.safeW {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 70px 0;
  display: flex;
  .faq {
    cursor: pointer;
    margin-top: 30px;
    margin-right: 100px;
    font-size: 50px;
    color: #fff;
  }
}
.qatitle {
  color: #2f2f2f;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 94px;
}

.qaUnit {
  border-bottom: 1px solid #acacac;
  margin-bottom: 20px;
  > .q {
    font-size: 20px;
    color: #fff;
    padding: 20px;
    padding-left: 40px;
    padding-right: 60px;
    position: relative;
    cursor: pointer;
    > i {
      position: absolute;
      left: 20px;
      top: 23px;
      width: 4px;
      height: 22px;
      background: #da3c3f;
    }
    > .arrowdown {
      position: absolute;
      right: 20px;
      top: 23px;
      i {
        font-weight: 700;
        font-size: 30px;
      }
    }
  }
  > .a {
    border-top: 1px solid #dcddde;
    padding: 20px;
    font-size: 16px;
    word-break: break-word;
    color: #d7d3d3;
    > .more {
      text-align: right;
      text-decoration: underline;
      color: #6b89e7;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.logoBox {
  background: #fff;
  margin: 0 auto !important;
  left: 0 !important;
  overflow: hidden;
  border-radius: 40px;
  width: 1200px !important;
  .bn {
    cursor: pointer;
    width: 100% !important;
  }
  .logoList {
    margin: 70px auto;
    display: flex;
    .r {
      flex: 1;
    }
  }
}

.row {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .l {
    text-align: right;
    width: 151px;
    margin-right: 10px;
  }
  img {
    width: auto !important;
    margin-right: 5px;
  }
}

.row11 {
  height: 18px;
}
.row12 {
  height: 17px;
}
.row13 {
  height: 24px;
}
.row21 {
  height: 42px;
}
.row22 {
  height: 60px;
}

.row31 {
  height: 25px;
}
.row32 {
  height: 44px;
}
.row33 {
  height: 40px;
}
.row41 {
  height: 40px;
}
.row42 {
  height: 42px;
}
.row43 {
  height: 43px;
}
.row44 {
  height: 23px;
}
.row51 {
  height: 25px;
}
.row61 {
  height: 32px;
}
.row62 {
  height: 30px;
}
.row63 {
  height: 25px;
}
.row71 {
  height: 40px;
}
.row72 {
  height: 40px;
}
.row73 {
  height: 27px;
}
.row81 {
  height: 40px;
}
.row82 {
  height: 40px;
}
.row83 {
  height: 40px;
}
.row91 {
  height: 20px;
}
.row92 {
  height: 27px;
}
.row93 {
  height: 40px;
}
.row101 {
  height: 0px;
}

.row23 {
  height: 30px;
}

.row74 {
  height: 26px;
}
.bgtt {
  color: #fff;
  position: absolute;
  top: 200px;
  left: 355px;
  .na {
    font-family: MiSans, MiSans;
    font-weight: bold;
    font-size: 56px;
  }
  .our {
    font-size: 26px;
  }
}
.news {
  width: 1200px;
  margin: 0 auto;
}
.newstitle {
  margin-bottom: 62px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .l {
    font-size: 50px;
    font-weight: bold;
  }
  .r {
    text-decoration: underline;
    font-size: 18px;
    cursor: pointer;
  }
}
.cards {
  margin-top: 50px;
  justify-content: space-between;
}
.card {
  margin-bottom: 42px;
  width: 355px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.16);
  .card-img {
    width: 100%;
    height: 200px;
  }
  .card-body {
    padding: 23px;
    .card-title {
      height: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      font-weight: 600;
      font-size: 20px;
      color: #fff;
      line-height: 30px;
      margin-bottom: 18px;
    }
    .card-time {
      font-size: 13px;
      line-height: 22px;
      margin-bottom: 5px;
    }
    .card-knowmore {
      font-size: 15px;
      font-weight: 400;
      color: #4cd343;
      line-height: 24px;
      text-decoration: underline;
    }
  }
}
.cardTitle {
  text-align: center;
  margin: 0 auto;
  width: 1000px;
  line-height: 200px;
  font-size: 62px;
  border-bottom: 1px solid #707070;
}
.el-card {
  border: 0px;
  background: linear-gradient(145deg, #2c2b2b 0%, #2c2b2b 100%);
  color: #707070;
}
::v-deep {
  .card .card-body .card-time {
    font-size: 18px !important;
  }
}
</style>
