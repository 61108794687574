var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.goout),expression:"goout"}],staticClass:"mask",on:{"click":function($event){_vm.goout = false}}},[_c('div',{staticStyle:{"position":"relative","height":"100%"}},[_c('div',{staticClass:"box",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"confirm"},[_vm._v("Confirm to sign out？")]),_c('div',{staticClass:"btns"},[_c('div',{staticClass:"yes",on:{"click":_vm.logout}},[_vm._v("Yes")]),_c('div',{staticClass:"Cancel",on:{"click":function($event){$event.stopPropagation();_vm.goout = false}}},[_vm._v("Cancel")])])])])]),_c('div',{staticClass:"fixTop",style:({backgroundColor: _vm.bgColor})},[_c('div',{staticClass:"nav"},[_c('img',{staticClass:"cursor",attrs:{"src":require("../assets/images/xuexi/logo.png"),"alt":""},on:{"click":function($event){return _vm.$router.push('/')}}}),_c('div',{staticClass:"tabs"},[_c('div',{class:{ hit: _vm.currentNav == 1 },on:{"click":function($event){return _vm.$router.push({
              name: 'aboutdmz',
            })}}},[_vm._v(" ABOUT ")]),_c('div',{class:{ hit: _vm.currentNav == 2 },on:{"click":function($event){return _vm.$router.push({
              name: 'solution',
            })}}},[_vm._v(" SOLUTION ")]),_c('div',{class:{ hit: _vm.currentNav == 3 },on:{"click":function($event){return _vm.$router.push({
              name: 'stra',
            })}}},[_vm._v(" PARTNERS ")]),_c('div',{class:{ hit: _vm.currentNav == 4 },on:{"click":function($event){return _vm.$router.push({
              name: 'knowledge',
            })}}},[_vm._v(" FAQ ")]),_c('div',{class:{ hit: _vm.currentNav == 5 },on:{"click":function($event){return _vm.$router.push({
              name: 'news',
            })}}},[_vm._v(" NEWS ")])]),(!!_vm.token)?_c('div',{staticClass:"loginIcon"},[_c('connectWallet',{staticStyle:{"margin-right":"30px"}}),_c('div',{staticStyle:{"margin-right":"27px"}},[_c('el-dropdown',{attrs:{"placement":"bottom"}},[_c('div',{on:{"click":function($event){return _vm.$router.push({
                  name: 'messageList',
                })}}},[_c('el-badge',{staticClass:"badge",attrs:{"value":_vm.total_count ? _vm.total_count : '',"max":99}},[_c('img',{staticClass:"img50",attrs:{"src":require("../assets/images/mycenter/msg11.png"),"alt":""}})])],1),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('div',{staticClass:"messageBox"},[_c('div',{staticClass:"flexBox",staticStyle:{"justify-content":"space-between"}},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/mycenter/msg.png"),"alt":"","width":"20px"}}),_c('span',{staticStyle:{"font-weight":"bold","font-size":"18px","margin-left":"7px"}},[_vm._v("Notifications")])])]),_c('div',{staticClass:"fiveMsgList"},[_vm._l((_vm.unReadMessage),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"flexBox",on:{"click":function($event){return _vm.$router.push({
                          name: 'messageDetail',
                          params: {
                            id: item.message_guid,
                          },
                        })}}},[_c('div',{staticStyle:{"margin-right":"15px"}},[_c('div',{staticClass:"logoBox"},[_c('img',{attrs:{"src":item.logo_url,"alt":""}})])]),_c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticClass:"flexBox",staticStyle:{"justify-content":"space-between"}},[_c('div',[_vm._v(_vm._s(item.publisher))]),_c('div',{staticStyle:{"color":"#bebebe"}},[_vm._v(" "+_vm._s(item.ctime)+" ")])]),_c('div',{staticStyle:{"font-size":"14px","font-weight":"bold","line-height":"30px"}},[_vm._v(" Title:"+_vm._s(item.title)+" ")]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(item.abstract)+" ")])])])])}),(!_vm.unReadMessage.length)?_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" No More ")]):_vm._e()],2),(!!_vm.unReadMessage.length)?_c('div',{staticClass:"readMore",on:{"click":function($event){return _vm.$router.push({
                      name: 'messageList',
                    })}}},[_vm._v(" VIEW MORE ")]):_vm._e()])])],1)],1),_c('el-dropdown',{attrs:{"placement":"bottom-end"}},[_c('span',{staticClass:"el-dropdown-link"},[(_vm.cateG == 2 || _vm.cateG == 6)?_c('div',{staticClass:"m",on:{"click":function($event){return _vm.$router.push({
                  name: 'mint',
                })}}},[_c('img',{staticClass:"img50",attrs:{"src":require("../assets/images/mycenter/people.png"),"alt":""}})]):(_vm.cateG == 4)?_c('div',{staticClass:"m",on:{"click":function($event){return _vm.$router.push({
                  name: 'dmzmy',
                })}}},[_c('img',{staticClass:"img50",attrs:{"src":require("../assets/images/mycenter/people.png"),"alt":""}})]):_vm._e()]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',[_c('div',{staticClass:"flexBox",staticStyle:{"align-items":"center"}},[_c('div',{staticStyle:{"margin-right":"21px"}},[_c('img',{staticClass:"img50",staticStyle:{"margin-top":"15px"},attrs:{"src":require("../assets/images/mycenter/people.png"),"alt":""}})]),_c('div',[_c('div',{staticClass:"info"},[_c('div',[_vm._v(_vm._s(_vm.emailx))])])])])]),_c('el-dropdown-item',[(_vm.cateG == 2 || _vm.cateG == 6)?_c('div',{staticClass:"droItem",on:{"click":function($event){return _vm.$router.push({
                    name: 'mint',
                  })}}},[_c('img',{staticClass:"setimg",attrs:{"src":require("../assets/images/mycenter/dashboard.png"),"alt":""}}),_c('span',[_vm._v("Dashboard")])]):(_vm.cateG == 4)?_c('div',{staticClass:"droItem",on:{"click":function($event){return _vm.$router.push({
                    name: 'dmzmy',
                  })}}},[_c('img',{staticClass:"setimg",attrs:{"src":require("../assets/images/mycenter/dashboard.png"),"alt":""}}),_c('span',[_vm._v("Dashboard")])]):_vm._e()]),_c('el-dropdown-item',[_c('div',{staticClass:"droItem",on:{"click":function($event){return _vm.$router.push({
                    name: 'setting',
                  })}}},[_c('img',{staticClass:"setimg",attrs:{"src":require("../assets/images/mycenter/set.png"),"alt":""}}),_c('span',[_vm._v("Settings")])])]),_c('el-dropdown-item',[_c('div',{staticClass:"droItem",on:{"click":function($event){return _vm.$router.push({
                    name: 'fileList',
                  })}}},[_c('img',{staticClass:"setimg",attrs:{"src":require("../assets/images/mycenter/docu.png"),"alt":""}}),_c('span',[_vm._v("Documents")])])]),_c('el-dropdown-item',[_c('div',{staticClass:"droItem",on:{"click":function($event){return _vm.$router.push({
                    name: 'knowledge',
                  })}}},[_c('img',{staticClass:"setimg",attrs:{"src":require("../assets/images/mycenter/help.png"),"alt":""}}),_c('span',[_vm._v("Help")])])]),_c('el-dropdown-item',[_c('div',{staticClass:"droItem",on:{"click":function($event){_vm.goout = true}}},[_c('img',{staticClass:"outimg",staticStyle:{"margin-right":"13x"},attrs:{"src":require("../assets/images/mycenter/sg.png"),"alt":""}}),_c('span',[_vm._v("Sign out")])])])],1)],1)],1):_c('div',{staticClass:"login"},[_c('div',{staticClass:"cursor",on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v("LOG IN")]),_c('img',{staticClass:"cursor",attrs:{"src":require("../assets/images/xuexi/reg.png"),"alt":""},on:{"click":function($event){return _vm.$router.push({
              name: 'contact',
            })}}})])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.about2show),expression:"about2show"}],staticClass:"bread",on:{"mouseenter":function($event){_vm.about2show = true},"mouseleave":function($event){_vm.about2show = _vm.fasle}}},[_c('div',{staticClass:"w1200"},[_c('div',{staticClass:"aboutMenu"},[_c('div',{on:{"click":function($event){return _vm.$router.push('/aboutdmz')}}},[_vm._v("About DMZ")]),_c('div',{on:{"click":function($event){return _vm.$router.push('/industry')}}},[_vm._v("Industry Pain Point")]),_c('div',{on:{"click":function($event){return _vm.$router.push('/team')}}},[_vm._v("Management Team")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.part2show),expression:"part2show"}],staticClass:"bread",on:{"mouseenter":function($event){_vm.part2show = true},"mouseleave":function($event){_vm.part2show = _vm.fasle}}},[_c('div',{staticClass:"w1200"},[_c('div',{staticClass:"aboutMenu"},[_c('div',{on:{"click":function($event){return _vm.$router.push('/stra')}}},[_vm._v("Strategic Partners")]),_c('div',{on:{"click":function($event){return _vm.$router.push('/aprtp')}}},[_vm._v("APRTP Members")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }