import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/index.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
  },
  {
    path: '/loginGuid',
    name: 'loginGuid',
    component: () => import('@/views/loginGuid.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact.vue'),
  },
  {
    path: '/knowledge',
    name: 'knowledge',
    component: () => import('@/views/knowledge.vue'),
  },

  {
    path: '/logincode',
    name: 'logincode',
    component: () => import('@/views/logincode.vue'),
  },

  {
    path: '/forgetpwdemail',
    name: 'forgetpwdemail',
    component: () => import('@/views/forgetpwdemail.vue'),
  },
  {
    path: '/forgetpwdemailstatic',
    name: 'forgetpwdemailstatic',
    component: () => import('@/views/forgetpwdemailstatic.vue'),
  },
  {
    path: '/forgetpwd',
    name: 'forgetpwd',
    component: () => import('@/views/forgetpwd.vue'),
  },

  {
    path: '/aboutdmz',
    name: 'aboutdmz',
    component: () => import('@/views/about/aboutdmz.vue'),
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('@/views/about/team.vue'),
  },

  {
    path: '/aprtp',
    name: 'aprtp',
    component: () => import('@/views/about/aprtp.vue'),
  },

  {
    path: '/stra',
    name: 'stra',
    component: () => import('@/views/about/stra.vue'),
  },

  {
    path: '/solution',
    name: 'solution',
    component: () => import('@/views/about/solution.vue'),
  },

  {
    path: '/solution2',
    name: 'solution2',
    component: () => import('@/views/about/solution2.vue'),
  },

  {
    path: '/industry',
    name: 'industry',
    component: () => import('@/views/about/industry.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/about/news.vue'),
  },
  {
    path: '/media',
    name: 'media',
    component: () => import('@/views/about/media.vue'),
  },
  {
    path: '/eventDetail/:id',
    name: 'eventDetail',
    component: () => import('@/views/about/eventDetail.vue'),
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('@/views/about/events.vue'),
  },
  {
    path: '/newsDetail/:id',
    name: 'newsDetail',
    component: () => import('@/views/about/newsDetail.vue'),
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: () => import('@/views/about/blogs.vue'),
  },
  {
    path: '/blogDetail/:id',
    name: 'blogDetail',
    component: () => import('@/views/about/blogDetail.vue'),
  },
  // {
  //   path: '/burn',
  //   name: 'burn',
  //   component: () => import('@/views/fund/burn.vue'),
  // },
  // {
  //   path: '/mint',
  //   name: 'mint',
  //   component: () => import('@/views/fund/mint.vue'),
  // },
  // {
  //   path: '/onboard',
  //   name: 'onboard',
  //   component: () => import('@/views/fund/onboard.vue'),
  // },
  // {
  //   path: '/addEntity',
  //   name: 'addEntity',
  //   component: () => import('@/views/fund/add.vue'),
  // },
  // {
  //   path: '/fdetail',
  //   name: 'fdetail',
  //   component: () => import('@/views/fund/detail.vue'),
  // },

  {
    path: '/fundmy',
    name: 'fundmy',
    component: () => import('@/views/fund/my.vue'),
    redirect: '/mint',
    children: [
      {
        path: '/burn',
        name: 'burn',
        component: () => import('@/views/fund/burn.vue'),
      },
      {
        path: '/mint',
        name: 'mint',
        component: () => import('@/views/fund/mint.vue'),
      },
      {
        path: '/onboard',
        name: 'onboard',
        component: () => import('@/views/fund/onboard.vue'),
      },
      {
        path: '/addEntity',
        name: 'addEntity',
        component: () => import('@/views/fund/add.vue'),
      },
      {
        path: '/fdetail',
        name: 'fdetail',
        component: () => import('@/views/fund/detail.vue'),
      },
      {
        path: '/restake',
        name: 'restake',
        component: () => import('@/views/fund/stake.vue'),
      },
      {
        path: '/vaultunstake',
        name: 'vaultunstake',
        component: () => import('@/views/fund/unstake.vue'),
      },
      {
        path: '/vaultunstakevote',
        name: 'vaultunstakevote',
        component: () => import('@/views/fund/unstakereq.vue'),
      },
      {
        path: '/transfer',
        name: 'transfer',
        component: () => import('@/views/fund/transfer.vue'),
      },
      {
        path: '/unstakehelp',
        name: 'unstakehelp',
        component: () => import('@/views/fund/unstakeHelp.vue'),
      },
      {
        path: '/vaultBalance',
        name: 'vaultBalance',
        component: () => import('@/views/fund/vaultBalance.vue'),
      },
      {
        path: '/unstakeaudit',
        name: 'unstakeaudit',
        component: () => import('@/views/fund/unstakeAudit.vue'),
      },
    ],
  },
  {
    path: '/dmzmy',
    name: 'dmzmy',
    component: () => import('@/views/dmz/my.vue'),
    redirect: '/unstakeVoting',
    children: [
      {
        path: '/unstakeVoting',
        name: 'unstakeVoting',
        component: () => import('@/views/dmz/unstakeVoting.vue'),
      },
      {
        path: '/dmzonboard',
        name: 'dmzonboard',
        component: () => import('@/views/dmz/onboard.vue'),
      },

      {
        path: '/ddetail',
        name: 'ddetail',
        component: () => import('@/views/dmz/detail.vue'),
      },
    ],
  },

  {
    path: '/my/home',
    name: 'my',
    component: () => import('@/views/My/index.vue'),
    redirect: '/my/index',
    children: [
      {
        path: '/my/index',
        name: 'myindex',
        component: () => import('@/views/My/home.vue'),
        redirect: '/my/asset',

        children: [
          {
            path: '/my/asset',
            name: 'asset',
            component: () => import('@/views/My/walletplay/asset.vue'),
          },
          {
            path: '/investDetail',
            name: 'investDetail',
            component: () => import('@/views/investDetail.vue'),
          },
          {
            path: '/my/mint',
            name: 'mint',
            component: () => import('@/views/My/walletplay/mint.vue'),
          },
          {
            path: '/my/stake',
            name: 'stake',
            component: () => import('@/views/My/walletplay/stake.vue'),
          },
          {
            path: '/my/unstake',
            name: 'unstake',
            component: () => import('@/views/My/walletplay/unstake.vue'),
          },
          {
            path: '/my/burn',
            name: 'burn',
            component: () => import('@/views/My/walletplay/burn.vue'),
          },
        ],
      },

      {
        path: '/my/messageList',
        name: 'messageList',
        component: () => import('@/views/My/messageList.vue'),
      },
      {
        path: '/my/messageDetail/:id',
        name: 'messageDetail',
        component: () => import('@/views/My/messageDetail.vue'),
      },
      {
        path: '/my/fileList',
        name: 'fileList',
        component: () => import('@/views/My/fileList.vue'),
      },
      {
        path: '/my/setting',
        name: 'setting',
        component: () => import('@/views/My/setting.vue'),
      },
    ],
  },
  {
    // 重定向路由
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    console.log(to, from, savedPosition)
    return { x: 0, y: 0 }
  },
})

export default router
