var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_vm._m(0),_c('div',{staticClass:"news"},[_c('div',{staticClass:"newstitle"},[_c('div',{staticClass:"l"},[_vm._v("What’s New")]),_c('div',{staticClass:"r",on:{"click":function($event){return _vm.$router.push('/news')}}},[_vm._v("View More")])]),_c('div',{staticClass:"cards flexBox"},_vm._l((_vm.list),function(item){return _c('div',{key:item.guid,staticClass:"card",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
            path: `/newsDetail/${item.guid}`,
          })}}},[_c('el-card',[_c('div',[_c('img',{staticClass:"card-img",attrs:{"src":item.article_image,"alt":""}}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-title"},[_vm._v(" "+_vm._s(item.article_title)+" ")]),_c('div',{staticClass:"card-time"},[_vm._v(" Release date："+_vm._s(_vm.$dayjs(item.article_publish_time * 1000).format('ll'))+" ")])])])])],1)}),0)]),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"qabox"},[_c('div',{staticClass:"safeW"},[_c('div',{staticClass:"faq",on:{"click":function($event){return _vm.$router.push({
            name: 'knowledge',
          })}}},[_vm._v(" FAQ ")]),_c('div',{staticClass:"qacontainer",staticStyle:{"flex":"1"}},_vm._l((_vm.qList),function(item,index){return _c('div',{key:index,staticClass:"qaUnit"},[_c('div',{staticClass:"q",on:{"click":function($event){return _vm.qslide(index)}}},[_c('div',{staticClass:"arrowdown"},[(_vm.qStateList[index] == true)?_c('i',{staticClass:"el-icon-minus"}):_c('i',{staticClass:"el-icon-plus"})]),_vm._v(" "+_vm._s(item.article_title)+" ")]),(_vm.qStateList[index] == true)?_c('div',{staticClass:"a"},[_c('div',{domProps:{"innerHTML":_vm._s(item.article_content)}})]):_vm._e()])}),0)])]),_c('Bottom')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fullImg"},[_c('div',[_c('img',{attrs:{"src":require("../assets/images/header/bg.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fullImg"},[_c('div',[_c('img',{attrs:{"src":require("../assets/images/index/homebar1.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fullImg"},[_c('div',[_c('img',{attrs:{"src":require("../assets/images/index/dmzcon.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fullImg"},[_c('div',[_c('img',{attrs:{"src":require("../assets/images/index/tes.png")}})])])
}]

export { render, staticRenderFns }