import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import cookie from 'js-cookie'
// import 'lib-flexible/flexible'
import MessagePlugin from '@/components/message.js'
Vue.use(MessagePlugin)
Vue.use(VueI18n)
Vue.prototype.cookie = cookie
// 设置 cookie 工具函数
Vue.prototype.set_cookie = (name, data, expires) => {
  cookie.set(name, data, {
    expires: expires,
  })
}

const i18n = {
  locale: cookie.get('lang') || 'zh', // 语言标识,第一次登录默认是中文
  messages: {
    zh: require('@/assets/language/local_zh'), // 汉语
    en: require('@/assets/language/local_en'), // 英语
  },
}

// import vconsole from 'vconsole'

// if (location.origin.indexOf('uat') >= 0) {
//   new vconsole()
// }
//new vconsole();

import ElementUI from 'element-ui'

import { global } from './utils/global'

import { Uploader } from 'vant'
Vue.use(Uploader)

Vue.prototype.global = global

import dayjs from 'dayjs'
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
const formatter = new Intl.NumberFormat('en-NZ', {
  minimumFractionDigits: 2,
})
Vue.prototype.$dayjs = dayjs
Vue.prototype.global = global

Vue.filter('numFormat', (str) => {
  return formatter.format(str)
})

const isFox = navigator.userAgent.indexOf('Firefox') > -1 ? true : false

// if(isFox){
//   import('./utils/rem.js')
// }

import VueBus from 'vue-bus'
Vue.use(VueBus)

import('./utils/rem.js')

//import 'lib-flexible/flexible'

import 'element-ui/lib/theme-chalk/index.css'
import './assets/yk_global.css'

import 'vant/lib/index.css'
import locale from 'element-ui/lib/locale/lang/en'
Vue.config.productionTip = false
//Vue.use(ElementUI,VueI18n);
Vue.use(ElementUI, { locale })
import { Icon } from 'vant'

Vue.use(Icon)
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
